import { StripeError } from '@stripe/stripe-js';
import { FirebaseFunctionsError } from '../api/cloudFunctions';

function errorIsFirebaseFunctionsError(e: any): e is FirebaseFunctionsError {
  return e.details !== undefined;
}

function errorIsStripeError(e: any): e is StripeError {
  return e.code !== undefined;
}

export function getErrorMessage(
  e: string | Error | StripeError | FirebaseFunctionsError,
  defaultMessage = 'An error occurred'
) {
  if (typeof e === 'string') {
    return e;
  }

  if (errorIsFirebaseFunctionsError(e)) {
    return e.details?.message;
  }

  if (errorIsStripeError(e)) {
    return `${e.type} - ${e.message}${e.code ? ` (${e.code})` : ''}`;
  }

  if (e instanceof Error) {
    return e.message;
  }

  return defaultMessage;
}
