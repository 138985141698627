import {
  IonModal,
  IonContent,
  IonHeader,
  IonButtons,
  IonButton,
  IonToolbar,
  IonTitle,
  IonFooter,
} from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import useIonicModalProps from '../../hooks/useIonicModalProps';
import { JSX } from '@ionic/core/components';
import Conversation from './Conversation';
import { CaseStatus, useCase } from '../../services/buildfire/rdb/cases';
import useCaseIdParam from '../../hooks/useCaseIdParam';
import Typography from '../common/Typography';
import { useFirebase } from '../../services/firebase';
import MessageInputBox from './MessageInputBox';

interface MessageModalProps extends JSX.IonModal {
  onClose?: () => void;
}

const Modal = styled(IonModal)`
  @media (min-width: 768px) {
    --width: 400px;
    &:not(.overlay-hidden) {
      z-index: 999 !important; // just below navbar z-index
      display: block;
      pointer-events: none;
    }
    // top: 50%;
    // right: 0% !important;
    // bottom: 0% !important;
    // left: 0% !important;
    // min-height: 240px !important;
    // width: 100% !important;

    &::part(backdrop) {
      display: none;
    }

    &::part(content) {
      position: absolute;
      right: 16px;
      bottom: 80px;

      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 4px;
    }
  }
`;
const ClinicName = styled(IonTitle)`
  font-size: 10px;
  color: var(--ion-color-secondary);
`;

const MinimizeButton = styled(IonButton)`
  &::part(native) {
    // font-size: 28px;
  }
`;

const Footer = styled(IonFooter)`
  padding: 8px;
  padding-bottom: max(var(--ion-safe-area-bottom), 8px);
`;

const MessageModal: React.FC<MessageModalProps> = ({ ...props }) => {
  const modalProps = useIonicModalProps();
  const caseId = useCaseIdParam();
  const { case: c } = useCase(caseId);
  const { currentUser } = useFirebase();
  if (!c) {
    return null;
  }

  const isAdmin = currentUser?.uid !== c?.uid;
  const name = isAdmin
    ? `${c?.submitter.first} ${c?.submitter.last}`
    : 'Pathologist';
  return (
    <Modal
      showBackdrop={false}
      backdropDismiss={false}
      id="message-modal"
      {...modalProps}
      {...props}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <Typography>{name}</Typography>
            {isAdmin && (
              <Typography fontSize="10px" variant="secondary">
                {c?.user.displayName}
              </Typography>
            )}
          </IonTitle>
          <IonButtons slot="end">
            <MinimizeButton title="Minimize" onClick={() => props.onClose?.()}>
              Close
            </MinimizeButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Conversation />
      </IonContent>
      <Footer>
        {/* <IonToolbar> */}
        <MessageInputBox
          caseId={c.id}
          disabled={c.status === CaseStatus.CLOSED && !isAdmin}
        />
        {/* </IonToolbar> */}
      </Footer>
    </Modal>
  );
};

export default MessageModal;
