import React from 'react';
import { getVersion } from '../util/app';
import { useIonModal } from '@ionic/react';
import AppVersionUpdateModal from '../components/modals/AppVersionUpdateModal';
import { getLatestVersion } from '../services/buildfire/rdb/version';
import { getDatabase } from 'firebase/database';
import { useFirebase } from '../services/firebase';
import { FirebaseApp } from 'firebase/app';

/**
 * Checks the latest app version against the current version
 * Returns true if the minor version of this app is out of date
 */
async function checkVersion(app: FirebaseApp) {
  const db = getDatabase(app);
  const latestVersion = await getLatestVersion(db);
  if (!latestVersion) {
    return false;
  }

  const [_, latestMinorVersion] = latestVersion.split('.');
  const version = await getVersion();
  const [major, minor] = version.split('.');
  return Number(minor) < Number(latestMinorVersion);
}

function useAppVersionCheck() {
  const { app } = useFirebase();
  const [present] = useIonModal(AppVersionUpdateModal, {
    canDismiss: false,
    isOpen: true,
  });

  React.useEffect(() => {
    checkVersion(app).then((isOutOfDate) => {
      if (isOutOfDate) {
        const canOverride = process.env.NODE_ENV === 'development';
        present({
          canDismiss: canOverride,
          backdropDismiss: canOverride,
        });
      }
    });
  }, []);
}

export default useAppVersionCheck;
