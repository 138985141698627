import {
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonPage,
  IonSearchbar,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import React from 'react';
import Toolbar from '../components/common/Toolbar';
import AlertBanner from '../components/common/AlertBanner';
import { UserMetadataWithId } from '../services/firebase/user';
import UserListItem from '../components/users/UserListItem';
import styled from 'styled-components';
import Typography from '../components/common/Typography';
import { User } from 'firebase/auth';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SplitPane from '../components/common/SplitPane';
import { isDesktop, isSafari } from '../util/device';
import useQueryParams from '../hooks/useQueryParams';
import FixedProgressBar from '../components/common/FixedProgressBar';
import Fuse from 'fuse.js';
import UserView from './User';
import classNames from 'classnames';
import NoUserSelected from '../components/user/NoUserSelected';
import { getUserById } from '../api/cloudFunctions';
import { FirebaseApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { useFirebase } from '../services/firebase';

type UserType = Partial<UserMetadataWithId> | User;

interface UsersProps {
  isAdmin: boolean;
  users: UserType[];
  onRemoveUser?: (user: UserType) => Promise<void>;
  isLoading: boolean;
  onLoadMoreUsers?: () => Promise<void>;
  disableInfiniteScroll?: boolean;
  onSearchUsers?: (searchTerm: string) => Promise<void>;
}

const SearchBarToolbar = styled<{ $isIOS: boolean } & any>(IonToolbar)`
  ${(props) =>
    props.$isIOS
      ? '--padding-top: 0px !important; padding-top: 0px !important;'
      : ''}

  &.ios form {
    padding-top: 15px;
  }
`;
const TitleContainer = styled.div`
  padding: 0 16px;
`;

const UserListContainer = styled(IonContent)`
  // subtract searchbar height
  height: calc(100% - 56px);
`;

const NoUsers: React.FC<any> = () => {
  return (
    <Typography fontSize="18px" textAlign="center" margin="32px">
      No users
    </Typography>
  );
};

const Hr = () => <div style={{ margin: 0 }} className="mdc-list-divider" />;

const fuseOptions: Fuse.IFuseOptions<UserType> = {
  keys: ['displayName', 'email', 'uid'],
};
const filterCases = (searchTerm: string, users: UserType[]): UserType[] => {
  const fuse = new Fuse(users, fuseOptions);
  return fuse.search(searchTerm).map((result) => result.item);
};

function useFilteredUsers(searchUsers: UserType[]) {
  const queryParams = useQueryParams();
  const search = queryParams.get('search');
  const isSearching = !!search;
  const [filteredUsers, setFilteredUsers] = React.useState<UserType[]>([]);
  React.useEffect(() => {
    const filteredSearchUsers = isSearching
      ? filterCases(search!, searchUsers)
      : [];
    setFilteredUsers(filteredSearchUsers);
  }, [search, searchUsers]);

  return { isSearching, filteredUsers };
}

function useUser(
  app: FirebaseApp,
  userId: string,
  users: UserType[]
): UserType | undefined {
  const [user, setUser] = React.useState<UserType | undefined>(undefined);
  React.useEffect(() => {
    const existingUser = users.find((u) => u.uid === userId);
    if (existingUser) {
      setUser(existingUser);
    } else {
      const fns = getFunctions(app);
      getUserById(fns, { userId })
        .then((result) => {
          if (result) {
            setUser(result.user);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [userId, users]);

  return user;
}

const Users: React.FC<UsersProps> = ({
  isLoading,
  users,
  onRemoveUser,
  onLoadMoreUsers,
  children,
  disableInfiniteScroll,
  isAdmin,
  onSearchUsers,
}) => {
  const { app } = useFirebase();
  const { userId } = useParams<{ userId: string }>();
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const user = useUser(app, userId, users);
  const showDetails = !isLoading && !!user;

  const { isSearching, filteredUsers } = useFilteredUsers(users);

  const handleSearchTermChange = (searchTerm = '') => {
    const params = new URLSearchParams(location.search);
    if (searchTerm) {
      params.set('search', searchTerm || '');
    } else {
      params.delete('search');
    }
    history.replace({
      pathname: isAdmin ? '/admin/users' : '/users',
      search: params.toString(),
    });
  };

  const usersToDisplay = isSearching ? filteredUsers : users;

  const isIos = isPlatform('ios') || isSafari;
  const userList = (
    <>
      <IonHeader>
        <SearchBarToolbar $isIOS={isIos}>
          <form
            action="search"
            onSubmit={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              const el = document.getElementById('user-search');
              const search = (el as any)?.value;

              await onSearchUsers?.(search);
              handleSearchTermChange(search);
            }}
          >
            <IonSearchbar
              id="user-search"
              value={queryParams.get('search')}
              showClearButton="always"
              onIonClear={() => handleSearchTermChange()}
              onIonChange={(e) => {
                if (e.target.value === '') {
                  handleSearchTermChange();
                }
              }}
            />
          </form>
        </SearchBarToolbar>
      </IonHeader>

      <UserListContainer>
        <FixedProgressBar show={isLoading} top={56} indeterminate />
        <IonList>
          {usersToDisplay.map((u, i) => (
            <UserListItem
              // key falls back to index since pending users don't have IDs
              key={u.uid || i}
              user={u}
              onRemove={onRemoveUser ? () => onRemoveUser(u) : undefined}
              selected={(u.uid || i) === userId}
            />
          ))}
          {!isLoading && usersToDisplay.length === 0 && (
            <NoUsers>No users</NoUsers>
          )}
        </IonList>
        {onLoadMoreUsers && (
          <IonInfiniteScroll
            threshold="100px"
            disabled={isLoading || users.length === 0 || disableInfiniteScroll}
            onIonInfinite={async (event) => {
              await onLoadMoreUsers();
              event.target.complete();
            }}
          >
            <IonInfiniteScrollContent loadingText="Loading..." />
          </IonInfiniteScroll>
        )}
      </UserListContainer>
    </>
  );

  const userInfo = user ? <UserView user={user} isAdmin={isAdmin} /> : null;
  const showMainHeader = isDesktop();

  return (
    <IonPage>
      <IonHeader
        className={classNames({
          ['ion-no-border']: true,
          // ['hide-sm']: !showMainHeader,
        })}
      >
        <Toolbar showBackButton title="Users" />
        <AlertBanner />
      </IonHeader>
      <IonContent>
        <SplitPane
          list={userList}
          details={showDetails ? userInfo : undefined}
          fallback={<NoUserSelected />}
        />
        {children}
      </IonContent>
    </IonPage>
  );
};

export default Users;
