import { Database, onValue, ref } from 'firebase/database';

export const getLatestVersion = async (db: Database) => {
  const versionRef = ref(db, '/version');
  return new Promise<string>((resolve, reject) => {
    onValue(
      versionRef,
      (snap) => {
        if (!snap.exists()) {
          return resolve('');
        }

        resolve(snap.val());
      },
      reject,
      { onlyOnce: true }
    );
  });
};
