import {
  IonButton,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import Typography from '../common/Typography';
import Logo from '../common/Logo';
import { isPlatform } from '@ionic/core';
import { Capacitor } from '@capacitor/core';
import { NativeMarket } from '@capacitor-community/native-market';

interface AppVersionUpdateModalProps {}

const Content = styled.div`
  padding: 64px 24px 36px 24px;
  display: flex;
  flex-direction: column;
`;
const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 64px;

  > img {
    max-width: min(400px, 100%);
  }
`;

function getAppUpdateLink() {
  const platform = Capacitor.getPlatform();
  if (platform === 'android') {
    return `https://play.google.com/store/apps/details?id=com.pocketpathologist.app&hl=en_US&gl=US&pli=1`;
  }
  return `https://apps.apple.com/us/app/pocket-pathologist/id1609678503`;
}

function getAppId() {
  const platform = Capacitor.getPlatform();
  if (platform === 'android') {
    return 'com.pocketpathologist.app';
  }
  return 'id1609678503';
}

const AppVersionUpdateModal: React.FC<AppVersionUpdateModalProps> = (props) => {
  const isNativePlatform = Capacitor.isNativePlatform();
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>App Update Required</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <Content>
          <LogoContainer>
            <Logo />
          </LogoContainer>

          <Typography margin="12px 0">Your app is out of date.</Typography>
          <Typography margin="12px 0">
            {isNativePlatform
              ? 'Please update the app from the app store in order to continue using the app.'
              : 'Please refresh the page to update the app.'}
          </Typography>
          <IonButton
            onClick={() => {
              if (isNativePlatform) {
                NativeMarket.openStoreListing({
                  appId: getAppId(),
                }).catch((e) => {
                  console.error(e);
                  window.open(getAppUpdateLink(), '_blank');
                });
              } else {
                window.location.reload();
              }
            }}
          >
            {isNativePlatform ? 'Update App' : 'Refresh'}
          </IonButton>
        </Content>
      </IonContent>
    </>
  );
};

export default AppVersionUpdateModal;
